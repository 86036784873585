import React from 'react'
import { Link } from 'gatsby'
import { v4 } from 'uuid'
import Img from 'gatsby-image'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { FiChevronRight, FiChevronUp } from 'react-icons/fi'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import './Accordion.scss'

export default class PNPRAccordion extends React.Component {
  constructor(props) {
    super(props)

    const allImages = {}
    if (props.content.type === 'images') {
      props.content.items.forEach((item, itemIndex) => {
        const itemImages = []
        if (item.images) {
          item.images.forEach(image => {
            if (image.image.localFile) {
              itemImages.push(image.image)
            }
          })
        }
        allImages[itemIndex] = itemImages
      })
    }

    this.state = {
      photoIndex: 0,
      isOpen: false,
      allImages,
    }
  }

  drawPanelContent(item, type, panelIndex) {
    if (type === 'text') {
      return (
        <AccordionItemPanel>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </AccordionItemPanel>
      )
    }

    if (type === 'images' && item.images) {
      const content = item.images.map((image, i) => {
        if (image.image.localFile) {
          return (
            <div
              key={`panel-${panelIndex}-${i}`}
              className="w-1/2 sm:w-1/3 md:w-1/4"
            >
              <a
                href="#"
                title={image.image.title}
                onClick={e => this.openImage(e, panelIndex, i)}
              >
                <Img fixed={image.image.localFile.childImageSharp.fixed} />
              </a>
            </div>
          )
        }
      })

      return (
        <AccordionItemPanel>
          <div className="flex flex-wrap">{content}</div>
        </AccordionItemPanel>
      )
    }

    return null
  }

  openImage(e, panelIndex, imageIndex) {
    e.preventDefault()
    this.setState({
      isOpen: true,
      imageIndex,
      panelIndex,
    })
  }

  render() {
    const { content } = this.props
    const { allImages, imageIndex, panelIndex, isOpen } = this.state

    return (
      <div className="accordion-wrapper">
        <h3 dangerouslySetInnerHTML={{ __html: content.header }} />
        <Accordion allowZeroExpanded>
          {content.items.map((item, panelIndex) => (
            <AccordionItem key={`item-${panelIndex}`}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <FiChevronRight />
                  {item.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              {this.drawPanelContent(item, content.type, panelIndex)}
            </AccordionItem>
          ))}
        </Accordion>
        <div dangerouslySetInnerHTML={{ __html: content.footer }} />

        {isOpen && (
          <Lightbox
            mainSrc={
              allImages[panelIndex][imageIndex].localFile.childImageSharp
                .original.src
            }
            nextSrc={
              allImages[panelIndex][
                (imageIndex + 1) % allImages[panelIndex].length
              ].localFile.childImageSharp.original.src
            }
            prevSrc={
              allImages[panelIndex][
                (imageIndex + allImages[panelIndex].length - 1) %
                  allImages[panelIndex].length
              ].localFile.childImageSharp.original.src
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                imageIndex:
                  (imageIndex + allImages[panelIndex].length - 1) %
                  allImages[panelIndex].length,
              })}
            onMoveNextRequest={() =>
              this.setState({
                imageIndex: (imageIndex + 1) % allImages[panelIndex].length,
              })}
            imageCaption={allImages[panelIndex][imageIndex].title}
          />
        )}
      </div>
    )
  }
}
