import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PNPRAccordion from '../components/Accordion/Accordion'
import ContentSections from '../components/ContentSections/ContentSections'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export const PageTemplate = ({
  title,
  content,
  introSections,
  accordion,
  image,
}) => {
  return (
    <div className="px-8">
      <div
        className={`container max-w-3xl ${
          introSections ? 'border-b-2 border-gray-200 mb-8 pb-8' : ''
        }`}
      >
        <h1
          className="underlined"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <div>
          {image && image.localFile.childImageSharp && (
            <Img
              className="md:float-right md:ml-6 mb-6 featured-image"
              fixed={image.localFile.childImageSharp.fixed}
            />
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {accordion && <PNPRAccordion content={accordion} />}
        </div>
      </div>
      {introSections && (
        <ContentSections sections={introSections} width="max-w-3xl" />
      )}
    </div>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  const accordion = page.acf.accordion
    ? {
        items: page.acf.accordion,
        header: page.acf.accordion_header,
        footer: page.acf.accordion_footer,
        type: page.acf.accordion_type,
      }
    : null

  return (
    <Layout>
      <SEO title={page.title} />
      <PageTemplate
        title={page.title}
        content={page.content}
        introSections={page.acf.intro_sections}
        reverseIntroSectionsOrder={page.acf.reverse_intro_sections_order}
        accordion={accordion}
        image={page.featured_media}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 300, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      acf {
        accordion_header
        accordion_type
        accordion_footer
        accordion {
          title
          content
          images {
            image {
              title
              localFile {
                childImageSharp {
                  fixed(width: 150, height: 150) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  original {
                    src
                  }
                }
              }
            }
          }
        }
        intro_sections {
          title
          content
          footnote
          button_text
          button_url
          # button {
          #   title
          #   url
          # }
          image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 600
                  maxHeight: 400
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            noHeightRestriction: localFile {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            fullSize: localFile {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          show_full_image
          make_image_zoomable
        }
      }
    }
  }
`
